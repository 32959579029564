export function getDefaultOpenTradeFilter() {
  return ['order', 'account', 'symbolName', 'command', 'lots', 'openTime', 'openPrice', 'closeTime', 'sl', 'tp', 'profit', 'comment']
}

export function getDefaultCloseTradeFilter() {
  return ['order', 'account', 'symbolName', 'command', 'lots', 'openTime', 'openPrice', 'closeTime',  'closePrice', 'profit', 'comment']
}

export function getTradeFields() {
  return [
    'order', 'account', 'symbolId', 'symbolName', 'command', 'state',
    'lots', 'openTime', 'openPrice', 'closeTime', 'sl', 'tp',
    'commission', 'commissionAgent', 'swap',  'closePrice', 'profit', 'taxes',
    'comment',
  ]
}
