<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button hide-settings>
      <template #beforeDropdown>
        <b-button variant="success" class="btn-icon" v-b-tooltip v-bind:title="$t('operations.add')">
          <i class="fa-solid fa-plus"></i>
        </b-button>
        <b-button variant="primary" class="btn-icon mx-1" v-b-tooltip v-bind:title="$t('columns.self')"
                  @click="openColumnsModal">
          <i class="fa-solid fa-bars"></i>
        </b-button>
      </template>
    </breadcrumb-button>
    <!--Filters-->
    <filter-card  v-model="filter" v-bind:fields="filterFields"/>
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t('menu.closedTrades') }}</h5>
      </b-card-header>
      <b-card-body>
        <closed-trade-table ref="closedTradeTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="true"
                        @selected="onClosedTradeSelected" :lazy="true" open-only>
          <template #table-top-actions="{selectedIds}">
            <b-button size="sm" id="set-representative" class="mr-1 d-inline-block client-action-button"
                      variant="outline-success"
            >
              <i class="fa-solid fa-times"></i>
              <span class="description">{{ $t('operations.close') }}</span>
            </b-button>
          </template>
        </closed-trade-table>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="tradesOpen" v-model="columns"
                   v-bind:columns-names="columnsNames" v-bind:columns-default="columnsDefaultNames"
    />
    <trade-order-modal ref="trade-order-modal" @close="refreshTables"/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import ClosedTradeSearchRequest from '@/model/trade/TradeSearchRequest';
import ColumnsModal from '@/components/modal/columnsModal';
import { getDefaultOpenTradeFilter, getTradeFields } from '@/model/trade/TradeColumns'
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinBase from '@/components/mixin/mixinBase';
import FilterCard from '@/components/widget/FilterCard';
import ClosedTradeFilterFields from '@/model/filter/TradeFilterFields';
import ClosedTradeTable from '@/components/tables/ClosedTradeTable';
import TradeOrderModal from '@/components/modal/tradeOrderModal.vue'

export default {
  name: 'OpenTradesView',
  components: {
    TradeOrderModal,
    ClosedTradeTable,
    FilterCard,
    ColumnsModal,
    BreadcrumbButton,
  },
  mixins: [mixinBase, mixinPermissions],
  data() {
    return {
      columnsNames: getTradeFields(),
      columnsDefaultNames: getDefaultOpenTradeFilter(),
      filter: ClosedTradeSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: null,
    };
  },
  watch: { },
  async created() {
    this.filterFields = ClosedTradeFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    ...mapActions('trade', ['getAllTrades']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
    onClosedTradeSelected(item) {
      // console.log("onClosedTradeSelected", selectedId)
      // this.$refs['trade-modal'].showModal(item.order, item.account);
      this.$refs['trade-order-modal'].showModal(null, item);
    },
  }
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
