import { render, staticRenderFns } from "./ClosedTradeTable.vue?vue&type=template&id=229b3b2d&scoped=true&"
import script from "./ClosedTradeTable.vue?vue&type=script&lang=js&"
export * from "./ClosedTradeTable.vue?vue&type=script&lang=js&"
import style0 from "./ClosedTradeTable.vue?vue&type=style&index=0&id=229b3b2d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "229b3b2d",
  null
  
)

export default component.exports