<template>
  <div v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <b-table responsive
             v-bind:items="trades"
             v-bind:fields="fields"
             no-local-sorting
             v-bind:sort-by.sync="sort.field"
             v-bind:sort-desc.sync="sortIsDesc"
             striped
             hover
             @sort-changed="onSortChanged"
             @row-clicked="onTradeRowClicked"
             v-bind:busy="loading"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline
        ></b-form-checkbox>
      </template>
      <template #cell(account)="data">
        <b-badge class="no-table-click" variant="primary" @click="showClient(clients[data.value].id)">
          {{ data.value }} -
          <span v-if="clients[data.value]!=null" class="d-inline-block m-0 text-capitalize">
          {{ clients[data.value].fullName }}
        </span>
        </b-badge>
      </template>

      <template #cell(created)="data">
        {{ data.value| momentDateTime }}
      </template>
      <template #cell(openTime)="data">
        {{ data.value| momentDateTime }}
      </template>
      <template #cell(closeTime)="data">
        {{ data.value| momentDateTime }}
      </template>
      <template #cell(command)="data">
        <b-badge :variant="getCommandVariant(data.value)">{{ data.value }}</b-badge>
      </template>

      <template #cell(lastUpdated)="data">
        {{ data.value| momentDateTime }}
      </template>


      <template #cell(alias)="data">
        <b-badge variant="dark">{{ data.value }}</b-badge>
      </template>

      <template #cell(profit)="data">
        <b-badge :variant="data.value>=0?'success':'danger'">{{ convertToEURO(data.value) }}</b-badge>
      </template>

      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.order]" inline @input="val=>toggleRow(data.item, val)"
        ></b-form-checkbox>
      </template>


    </b-table>


    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.trades') }}

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import ClientCell from '@/components/partial/clients/ClientCell';
import UserSelectBox from '@/components/widget/select/UserSelectBox';

export default {
  name: 'ClosedTradeTable',
  components: {
    ClientCell,
    UserSelectBox,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    initialLoad: true, // Add this flag
    trades: [],
    clients: {},
    title: '',
    showModal: false,
    selectedItem: {}, // Store the selected item
    selected: { all: false },
  }),
  props: {
    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },
    lazy: {
      default: () => false
    },
    closedOnly: {
      type: Boolean,
    },
    openOnly: {
      type: Boolean,
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // Skip the initial load call
        if (this.initialLoad) {
          this.initialLoad = false;
          return;
        }

        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 10, 50);
    if (!this.lazy) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById']),

    fields() {
      let $this = this;

      let fields = (this.columns || []).map(column => {
        let label = column.key === 'account' ? $this.$t('columns.client') : $this.$t(`columns.${column.key}`);
        return {
          key: column.key,
          label: label,
          sortable: true,
          active: column.enable,
        };
      });

      if (this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }

      return fields.filter(f => f.active === true);
    },
    selectedIds() {
      this.refreshKey; // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected)
          .filter(k => k !== 'all')
          .reduce((ans, order) => Object.assign(ans, { [order]: true }), {});
    },
  },
  methods: {
    ...mapActions('trade', ['getAllTrades','requestReopenOrder']),

    getCommandVariant(status) {

      switch (status) {
        case 'BUY':
          return 'success';
        case 'SELL':
          return 'danger';
        default:
          return 'primary';
      }
    },
    async refresh() {
      let $this = this;
      if (this.loading) {
        return;
      }
      if(this.closedOnly){
        this.filters['states'] = ['CLOSED', 'CLOSED_PART'];
      }
      if(this.openOnly){
        this.filters['states'] = ['OPEN'];
      }
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }

      // console.log("refresh()", {  pagination: this.pagination, sort})
      const result = await this.getAllTrades(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.trades') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.trades = result.data['trades'];
      this.clients = result.data['clients'];
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    },
    onTradeRowClicked(row, index, ev) {
      if(this.noRowClicked(row, index, ev) !== false){
        return
      }
      console.log("onTradeRowClicked order", row)
      this.$emit('selected', row)
    },
  }
};
</script>

<style scoped>
.custom-modal {
  min-width: 500px; /* Adjust the width as needed */
}

.btn-container .btn {
  margin-right: 2px;
}
</style>
